<template>
  <div class="slider-info-container">
    <v-label>{{proficiency.skillName}}</v-label>
    <v-slider
      class="skill-selector"
      v-model="proficiency.level"
      min="0"
      max="3"
      :color="colors.PRIMARY"
      tick-size="0"
      thumb-size="64"
      :always-dirty="true"
      :readonly="false"
      :tick-labels="tickLabels"
      v-on:change="saveProficiency()"
    >
      <template v-slot:thumb-label="props">
        <div style="text-align:center;">
          <span>{{ tickDescriptions[props.value] }}</span>
        </div>
      </template>
    </v-slider>
    <v-snackbar
      v-model="snackbarShown"
      :timeout="snackbarTimeout"
      :color="snackbarColor"
    >{{snackbarMessage}}</v-snackbar>
  </div>
</template>

<script>
import colors from "@/colors";
import proficiencyDataAccess from "@/dataAccess/proficiencyDataAccess";

export default {
  name: "surveyslider",
  data() {
    return {
      colors,
      proficiency: {
        id: 0,
        level: 0,
        skillName: this.skill.name,
        category: this.skill.category.name,
        lastUpdated: "",
        email: this.userEmail,
        tags: []
      },
      proficiencyDAO: new proficiencyDataAccess(),
      tickLabels: [0, 1, 2, 3],
      tickDescriptions: ["None", "Basic", "Good", "Proficient"],
      snackbarMessage: "Error saving skill",
      snackbarShown: false,
      snackbarTimeout: 2000,
      snackbarColor: "cc0000",
      currentProficiencies: []
    };
  },
  props: ["skill", "userEmail", "saveOnChange", "canEdit"],
  methods: {
    async saveProficiency() {
      try {
        this.currentProficiencies = await this.proficiencyDAO.getProficiencies(
          this.userEmail
        );
      } catch (err) {
        console.log(err);
        return;
      }
      var isNewProficiency = true;
      this.currentProficiencies.forEach(element => {
        if (this.proficiency.skillName == element.skillName) {
          isNewProficiency = false;
          return;
        }
      });
      if (isNewProficiency) {
        this.postProficiency();
      } else {
        if (this.proficiency.level == 0) {
          this.deleteProficiency();
        } else {
          this.putProficiency();
        }
      }
    },
    async deleteProficiency() {
      try {
        await this.proficiencyDAO.deleteProficiency(this.proficiency);
      } catch (err) {
        console.log(err);
        this.snackbarMessage = "Error deleting skill";
        this.snackbarShown = true;
        return;
      }
    },
    async postProficiency() {
      try {
        var response = await this.proficiencyDAO.postProficiency(
          this.proficiency
        );
        this.proficiency = response;
      } catch (err) {
        console.log(err);
        this.snackbarMessage = "Error saving skill";
        this.snackbarShown = true;
        return;
      }
    },
    async putProficiency() {
      try {
        var response = await this.proficiencyDAO.putProficiency(
          this.proficiency
        );
        this.proficiency = response;
      } catch (err) {
        console.log(err);
        this.snackbarMessage = "Error updating skill";
        this.snackbarShown = true;
        return;
      }
    }
  }
};
</script>

<style scoped>
.skill-selector {
  margin-right: 25px;
  width: 100%;
}

.date-label {
  font-size: 10pt;
  color: rgba(0, 0, 0, 0.54);
}

.slider-info-container {
  padding-top: 15px;
  padding-bottom: 5px;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  background-color: #fefefe;
}

.v-label.theme--light{
  text-align: left;
  max-width: 130px;
  min-width: 130px;
}
</style>



MORE VERT