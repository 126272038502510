<template>
  <div class="skillsurvey">
    <div class="title-bar">
      <h1 v-if="isViewingSelf === true">Skill Survey</h1>
      <h1 v-else-if="userViewing !== null">{{ userViewing.name }}'s Skill Survey</h1>
      <h1></h1>
    </div>
    <div class="survey-content">
        <SkillSurveyList :userEmailToView="selectedUser"/>
    </div>
  </div>
</template>

<script>
import SkillSurveyList from "./components/SkillSurveyList.vue";
import userDataAccess from '../../dataAccess/userDataAccess';

export default {
    name: "skillsurvey",
    components: {
        SkillSurveyList
    },
    data() {
        return {
            userViewing: null,
            userDAO: new userDataAccess(),
            isViewingSelf: false
        };
    },
    methods: {
        async initView() {
          let response = "";
          try {
            response = await this.userDAO.getAccount();
            if(this.selectedUser === undefined || this.selectedUser === "" || this.selectedUser == response.email){
              this.isViewingSelf = true;
              this.userViewing = response;
            }
            else {
              this.isViewingSelf = false;
              try {
                response = await this.userDAO.getAccount(this.selectedUser);
              } catch (err) {
                console.log(err);
                return;
              }
            }
          } catch (err) {
            console.log(err);
            return;
          }

          this.userViewing = response;
        }
    },
    mounted: async function() {
        await this.initView();
    },
    computed: {
        selectedUser() {
            return this.$route.query.user;
        },
        selectedUserEmail() {
            if (this.userViewing === null) {
                return "";
            }
            return this.selectedUser;
        },
    }
}
</script>

<style>
.survey-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 350px;
}

@media screen and (max-width: 796px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 1000px;
  }
}
</style>