<template>
  <div class="skill-survey-list skill-survey-container flex-child">
    <v-card class="title-bar darker">
      <div class="d-flex justify-between align-center">
        <h1>Add Skills</h1>
        <v-btn
          v-on:click="collapseExpand"
          :color="colors.PRIMARY"
          class="collapse-button"
          absolute
          bottom
          right
          fab
        >
          <v-icon v-if="!panel.includes(true)" :color="'white'">unfold_more</v-icon>
          <v-icon v-else :color="'white'">unfold_less</v-icon>
        </v-btn>
      </div>
      <p
        class="description grey--text"
      >Slide the sliders to add your skills. Sliding to a value 1 - 3 will add the skill to your list of skills. Unchanged skills will not be added.</p>
    </v-card>
    <v-expansion-panels v-model="panel" multiple>
      <v-expansion-panel  v-for="group in groupedSkills" :key="group[0].category.name">
        <v-expansion-panel-header>
          {{group[0].category.name}}
        </v-expansion-panel-header>
        <v-expansion-panel-content>

          <v-card v-for="skill in group" :key="skill.id" class="grey lighten-4">
            <SurveySlider
              :canEdit="canEdit"
              :skill="skill"
              :userEmail="userEmailToView"
              :saveOnChange="true"
            />
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-progress-circular
      v-if="isLoadingList"
      indeterminate
      :color="colors.PRIMARY"
      class="initialization-progress"
      :size="50"
      :width="3"
    ></v-progress-circular>
    <v-card class="d-flex justify-between align-center">
      <h2 v-if="this.groupedSkills.length == 0 && !isLoadingList">No more skills to add!</h2>
      <div class="done-button-card">
        <v-btn
          v-on:click="returnToSkillsPage()"
          :color="colors.PRIMARY"
          class="white--text done-button"
        >Done</v-btn>
      </div>
    </v-card>
  </div>
</template>

<script>
import colors from "@/colors";
import skillDataAccess from "@/dataAccess/skillDataAccess";
import _ from "lodash";
import SurveySlider from "./SurveySlider.vue";
import proficiencyDataAccess from "@/dataAccess/proficiencyDataAccess";

export default {
  name: "skillsurveylist",
  components: {
    SurveySlider
  },
  data() {
    return {
      colors,
      skillDAO: new skillDataAccess(),
      proficiencyDAO: new proficiencyDataAccess(),
      groupedSkills: [],
      currentProficiencies: [],
      skills: [],
      panel: [],
      isLoadingList: true
    };
  },
  props: ["userEmailToView", "canEdit"],
  mounted: async function() {
    await this.initializeList();
  },
  methods: {
    async initializeList() {
      this.skills = Object.values(
        Object.assign({}, await this.skillDAO.getAllSkills())
      );
      this.currentProficiencies = await this.proficiencyDAO.getProficiencies(
        this.userEmailToView
      );
      this.currentProficiencies.forEach(prof => {
        this.skills.forEach(skill => {
          if (skill.name == prof.skillName) {
            _.remove(this.skills, skill);
          }
        });
      });

      this.groupedSkills = Object.values(
        _.groupBy(this.skills, "category.name")
      );

      this.groupedSkills.sort((category1, category2) => {
        if(category1.length === 0 || category2.length === 0){
          return 0;
        }

        if (category1[0].category.name.toUpperCase() < category2[0].category.name.toUpperCase()) return -1;
        if (category1[0].category.name.toUpperCase() > category2[0].category.name.toUpperCase()) return 1;
        return 0;
      })

      this.groupedSkills.forEach(skillList => {
        skillList.sort((skill1, skill2) => {
          if (skill2.name.toUpperCase() < skill1.name.toUpperCase()) {
            return 1;
          }
          if (skill2.name.toUpperCase() > skill1.name.toUpperCase()) {
            return -1;
          }
          return 0;
        });
      });

      this.isLoadingList = false;
    },
    collapseExpand() {
      if (this.panel.length > 0) {
        this.panel = [];
      } else {
        this.panel = this.groupedSkills.map((groupedSkills, index) => index)
      }
    },
    returnToSkillsPage() {
      if (this.userEmailToView == undefined)
        this.$router.push("/skills")
      else
        this.$router.push("/skills?user=" + this.userEmailToView)
    }
  }
};
</script>

<style scoped>
.skill-survey-list {
  z-index: 0;
}
.category-wrapper {
  z-index: -1;
}

.skill-survey-container {
  color: #444444;
  box-shadow: 0px 1px 5px #aaaaaa;
  max-width: 600px;
  background-color: #fefefe;
}

.submit-button {
  width: 5%;
  padding: 0;
}

.flex-child {
  margin: auto 20px;
  margin-top: 20px;
  flex-direction: column;
  flex: 1 1 350px;
}

@media screen and (max-width: 796px) {
  .flex-child {
    margin: 20px 0;
    max-width: 1000px !important;
    width: 100%;
    flex: 1 1 1000px;
  }
}

.collapse-button {
  margin-right: 70px;
}

.done-button {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-right: 30px;
}

.done-button-card {
  text-align: center;
  width: 100%;
}

.description {
  margin-top: 10px;
}

.v-expansion-panel__header {
  display: none !important;
}

.initialization-progress {
  margin: 100px;
}
</style>