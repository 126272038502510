import apiWrapper from "@/apiWrapper";
import store from "@/store";
import proficiencyDataAccess from "@/dataAccess/proficiencyDataAccess";

class skillDataAccess {
    static cacheTimeoutMilliseconds = 1000 * 60 * 5;
    static lastGetSkillsUpdate = -1;

    async getAllSkills() {
        if (this.needsGetAllSkillsRefresh()) {
            
            let response = await apiWrapper.getSkills();
            store.commit("updateAllSkills", response.data);
            skillDataAccess.lastGetSkillsUpdate = new Date().getTime();
        }
        return store.getters.allSkillsList;
    }

    async getAllSkillsGrouped() {
        if (this.needsGetAllSkillsRefresh()) {
            skillDataAccess.lastGetSkillsUpdate = new Date().getTime();

            let response = await apiWrapper.getSkills();
            store.commit("updateAllSkills", response.data);
        }
        return store.getters.allSkillsGrouped;
    }

    async postSkill(skill) {
        let response = await apiWrapper.postSkill(skill);
        store.commit("addSkill", response.data);
        return store.getters.skill(response.data);
    }

    async putSkill(skill) {
        let response = await apiWrapper.putSkill(skill);
        store.commit("updateSkill", response.data);
        return store.getters.skill(response.data);
    }

    async mergeSkills(newSkill, skills){
        await apiWrapper.mergeSkills(newSkill, skills);
        skillDataAccess.lastGetSkillsUpdate = -1;
        proficiencyDataAccess.lastGetProficienciesUpdate = -1;
        await this.getAllSkills();
    }

    needsGetAllSkillsRefresh() {
        return skillDataAccess.lastGetSkillsUpdate === -1 ||
            new Date().getTime() - skillDataAccess.lastGetSkillsUpdate >= skillDataAccess.cacheTimeoutMilliseconds;
    }
}

export default skillDataAccess;